<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="title">回收站</div>
            <el-form class="search-form" inline :model="searchData" label-width="80px">
                <el-form-item>
                    <el-input v-model="searchData.name" placeholder="请输入商城名称" suffix-icon="el-icon-search"></el-input>
                </el-form-item>
                <el-form-item >
                    <el-button type="primary" @click="searchInfo">搜索</el-button>
                </el-form-item>
            </el-form>
            <infoTable class="info-table" :tableLoading="loading" :selection="false" :tableHeadConfig="headConfig" :tableLoadData="tableData">
                <template v-slot:operation="slotData">
                        <div class="table-oper" @click ="recovery(slotData.data.row)">
                            恢复
                        </div>
                        <div class="table-oper" @click="delInfo(slotData.data.row)">
                            删除
                        </div>
                    </template>
            </infoTable>
            <div class="pager">
                <infoPage :pagination="pagination" @pageclcik="pageclcik"></infoPage>
            </div>

        </div>
        <deldialog :appId="appId" :dialogVal="recDialog" title="您确定要恢复该商城吗?" @dialogChange="recoveryChange">
        </deldialog>
        <deldialog :appId="appId" :dialogVal="delDialog" title="您确定要删除该商城吗?" @dialogChange="delChange">
        </deldialog>
    </div>
</template>

<script>

    export default {
        data(){
            return{
                searchData:{
                    name:''
                },
                loading:false,
                headConfig:[ 
                    {
                        label: "ID",
                        field: "id",
                        sort:true
                    },
                    {
                        label: "商城名称",
                        field: "name"
                    },
                    {
                        label: "备注",
                        field: "remarks"
                    },
                    {
                        label: "回收时间",
                        field: "created_at"
                    },
                     {
                        label: "操作",
                        columnType: "slot",
                        slotName: "operation",
                    },
                ],
                tableData:[],
                pagination: {
                    currentpage: 1,
                    total: 1,
                    totalpage: 1,
                    pageSize: 8
                },
                recDialog:false,
                delDialog:false,
                appId:''
            }
        },
        mounted(){
            this.getRecycle()
        },
        methods:{
            searchInfo(){
                this.pagination.currentpage =1
                this.getRecycle()
            },
            getRecycle(){
                const that =this
                that.loading = true
                that.$request({
                    url:that.$api.recycleBin.getRecycle,
                    method:'post',
                    data:{
                        page:that.pagination.currentpage,
                        name:that.searchData.name
                    }
                }).then((res)=>{
                    that.loading = false
                    that.pagination.currentpage = res.data.current_page
                    that.pagination.total = res.data.total
                    that.pagination.totalpage = res.data.last_page
                    that.pagination.pageSize = res.data.per_page
                    that.tableData = res.data.data
                })
            },
            pageclcik(e) {
                this.pagination.currentpage = e
                this.getApp()
            },
            //恢复
            recovery(row){
                this.recDialog = true
                this.appId= row.id
            },
            recoveryChange(type,id){
                const that =this
                if(type == 1){
                    this.recDialog = false
                    that.$request({
                        url:that.$api.recycleBin.recovery,
                        method:'post',
                        data:{id}
                    }).then((res)=>{
                        that.getRecycle()
                    })
                }else{
                    this.recDialog = false
                }
            },
            // 删除
            delInfo(row){
                this.delDialog = true
                this.appId= row.id
            },
            delChange(type,id){
                console.log(type,id);
                const that = this
                this.delDialog = false
                if(type == 1){
                    that.$request({
                        url:that.$api.recycleBin.delRecycle,
                        method:'post',
                        data:{id}
                    }).then((res)=>{
                        that.getRecycle()
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        font-size: 22px;
        font-weight: 600;
    }

    .search-form{
        margin-top: 20px;
    }

    .info-table{
        min-height: 400px;

        .table-oper {
            display: inline-block;
            color: #4458FE;
            cursor: pointer;

            &:before {
                content: "|";
                margin: 0 5px;
                color: #4458FE;
            }

            &:first-child:before {
                display: none;
            }
        }
    }

    .pager{
        margin-top: 20px;
    }
</style>